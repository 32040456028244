import React from 'react'
import { Link } from 'gatsby'
import { Seo } from '@components'
import { PageLayout } from '../../components/site/layout/page-layout'

import { StaticImage } from 'gatsby-plugin-image'

const ProductOverviewPage = ({ location }) => {
  return (
    <PageLayout pathname={location.pathname}>
      {/* Big Brand start */}
      <div className="bg-[#122331] w-full min-h-[327px] h-[48vw] md:min-h-[474px] max-h-[474px]">
        <div className="container flex ">
          <div className="relative w-full md:w-1/2">
            <h1 className="text-[35px] md:text-[58px] font-black leading-[58px] self-center text-center tracking-tight mt-[60px] md:mt-[2.3em] md:ml-[-19px] text-[#acafb3]">
              What People<br></br>Are Saying<br></br>{' '}
              <span className="pb-[29px]">About Us</span>
            </h1>
            <hr className="border-[3.3px] bg-[#b91684] border-[#b91684] w-[42%] m-auto mt-[-17px] ml-[28%]" />
          </div>
          <div className="hidden md:block w-1/2 ml-[50px] mt-[124px] not-prose">
            <StaticImage
              src={`../../components/site/customers/images/message.png`}
              className="md:w-[397px] h-auto"
              alt="grey speech bubble icon with green rectangle overlay"
            />
          </div>
        </div>
      </div>
      {/* Big Brand end */}

      <div class="container py-[6rem]">
      <div className="block md:flex mb-[20px]">
          <div className="md:w-[22%] mt-[30px] md:mt-[0px] flex flex-wrap justify-center md:justify-end content-center pl-[15px] not-prose pr-[0px] md:pr-[25px] pb-[20px]">
            <StaticImage
              src={`../../components/site/customers/images/felyx_logo.png`}
              className="w-full max-w-[200px] h-auto"
              alt="felyx company logo"
            />
          </div>

          <div className="md:w-[40%] flex justify-center pl-[0px] md:pl-[6px] pt-[15px]">
            <div className="not-prose">
              <p className="text-[24px] md:text-[42px] font-black not-prose text-center md:text-left">
              Felyx
              </p>
              <div className="flex justify-center md:block">
                <div>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose ">
                  felyx is an innovative and forward-thinking scale-up that is focused on revolutionizing urban transportation across the Netherlands and Belgium. They achieve this through their shared e-moped solutions that offer sustainable and efficient transportation to all.
                  </p>
                  <p className="text-[18.7px] w-[94%] mt-[8px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose  italic">
                    “Before integrating
                      Approov, we were
                      concerned about the risk of
                      credential stuffing attacks
                      on our shared e-moped
                      platform. We realised that
                      we needed an out-of-thebox security solution that
                      enabled us to focus our
                      resources and productivity
                      on developing our core
                      product. Approov provided
                      the perfect solution to our
                      problem.”
                  </p>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-right italic not-prose ">
                    - Arthur Bloemen, Head of Product and Technology at felyx 
                  </p>
                </div>
              </div>
              <div className="md:block flex justify-center mt-[30px]">
                <Link
                  to="/customer/felyx/"
                  className="text-white bg-[#86b146] p-[5px] pl-[23px] pr-[23px] font-bold text-[20px] w-max text-center md:text-left not-prose rounded "
                >
                  Read Felyx's story
                </Link>
              </div>
            </div>
          </div>

          <div className="w-[100%] md:w-[40%] flex justify-center mt-[25px] md:mt-[0px] pr-[0px] md:pr-[10px]">
            <StaticImage
              src={`../../components/site/customers/images/felyx.jpg`}
              className="w-full max-w-[315px] md:max-w-[428px] h-auto"
              alt="felyx image"
            />
          </div>
        </div>

        <hr className="block md:hidden border-[3.3px] bg-[#b91684] border-[#b91684] w-[90%] m-auto mt-[40px] ml-[5%]" />

        {/* BMW Content start */}
        <div className="block md:flex md:flex-row-reverse mt-[40px]">


          <div className="md:w-[20%] mt-[30px] md:mt-[0px] flex flex-row md:flex-col justify-center content-center pl-[0px] md:pl-[15px] not-prose">
            <StaticImage
              src={`../../components/site/customers/images/BMW_logo.png`}
              className="w-full h-full max-w-[175px] max-h-[175px] h-auto"
              alt="BMW Company Logo"
            />
          </div>

          <div className="md:w-[40%] flex justify-center pl-[0px] md:pl-[6px] pt-[15px]">
            <div className="not-prose">
              <p className="text-[24px] md:text-[42px] font-black not-prose text-center md:text-left">
                BMW
              </p>
              <div className="flex justify-center md:block">
                <div>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose ">
                    The trend away from private car ownership towards shared
                    ownership and shared usage models is evident. The sharing
                    economy brings new use cases and use models that impact
                    platform security and user experience.
                  </p>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose italic">
                    The BMW Group Car Sharing Platform, including the Approov
                    SDK, is deployed in thousands of vehicles.
                  </p>
                  <div className="md:block flex justify-center mt-[30px]">
                    <Link
                      to="/customer/bmw/"
                      className="text-white bg-[#86b146] ml-[4px] p-[5px] pl-[23px] pr-[23px] font-bold text-[20px] w-max text-center md:text-left not-prose  rounded "
                    >
                      READ BMW'S STORY
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-[100%] md:w-[41%] flex justify-center content-center flex-wrap pl-[0px] md:pl-[10px] mt-[25px] md:mt-[0px]">
            <StaticImage
              src={`../../components/site/customers/images/BMW.png`}
              className="w-[315px] md:w-full md:max-w-[428px] h-auto"
              alt="Close up of BMW steering wheel with person holding a smartphone"
            />
          </div>

        </div>
        {/* BMW Content end */}
        <hr className="block md:hidden border-[3.3px] bg-[#b91684] border-[#b91684] w-[90%] m-auto mt-[40px] ml-[5%]" />

        {/* Papara Content start */}
        <div className="block md:flex mt-[50px] mb-[20px]">
          <div className="md:w-[22%] mt-[30px] md:mt-[0px] flex flex-wrap justify-center md:justify-end content-center pl-[15px] not-prose pr-[25px] pb-[20px]">
            <StaticImage
              src={`../../components/site/customers/images/Papara_logo.png`}
              className="w-full max-w-[200px] h-auto"
              alt="Papara Company Logo"
            />
          </div>

          <div className="md:w-[40%] flex justify-center pl-[6px] pt-[15px]">
            <div className="not-prose">
              <p className="text-[24px] md:text-[42px] font-black not-prose text-center md:text-left">
                Papara
              </p>
              <div className="flex justify-center md:block">
                <div>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose ">
                    Papara is a fast-growing <Link href="/market/fintech/">Fintech</Link> financial services company
                    delivering highly differentiated financial products that put
                    the user first. Its mobile channel is the primary access
                    point for these services and an attractive target for bad
                    actors.
                  </p>
                  <p className="text-[18.7px] w-[94%] mt-[8px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose  italic">
                    “Having completed the integration and test in less than 30
                    days, we deployed the Approov protection and instantly saw
                    the costs due to the fraudsters drop by 90%”.
                  </p>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-right italic not-prose ">
                    - Emre Kenci, CTO, Papara
                  </p>
                </div>
              </div>
              <div className="md:block flex justify-center mt-[30px]">
                <Link
                  to="/customer/papara/"
                  className="text-white bg-[#86b146] p-[5px] pl-[23px] pr-[23px] font-bold text-[20px] w-max text-center md:text-left not-prose rounded "
                >
                  READ PAPARA'S STORY
                </Link>
              </div>
            </div>
          </div>

          <div className="w-[100%] md:w-[40%] flex justify-center mt-[25px] md:mt-[0px] pr-[0px] md:pr-[10px]">
            <StaticImage
              src={`../../components/site/customers/images/Papara_image.png`}
              className="w-full max-w-[315px] md:max-w-[428px] h-auto"
              alt="Smart phone showing Papara mobile app; Papara bank cards on either side"
            />
          </div>
        </div>
        {/* Papara Content end */}
        <hr className="block md:hidden border-[3.3px] bg-[#b91684] border-[#b91684] w-[90%] m-auto mt-[40px] ml-[5%]" />

        {/* DEINdeal Content start */}
        <div className="block md:flex md:flex-row-reverse mt-[40px]">

          <div className="md:w-[20%] mt-[30px] md:mt-[0px] flex flex-row md:flex-col justify-center content-center pl-[0px] md:pl-[15px] not-prose">
            <StaticImage
              src={`../../components/site/customers/images/DEIN_deal_logo.png`}
              className="w-full max-w-[200px] h-auto"
              alt="Deindeal Company Logo"
            />
          </div>

          <div className="md:w-[40%] flex justify-center pl-[0px] md:pl-[6px] pt-[15px]">
            <div className="not-prose">
              <p className="text-[24px] md:text-[42px] font-black not-prose text-center md:text-left">
                Deindeal
              </p>
              <div className="flex justify-center md:block">
                <div>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose ">
                    Deindeal is an <Link href="/market/retail/">e-commerce</Link> success story based on the ability
                    to deliver desirable goods at attractive prices. Evolving
                    its platform to protect revenue against cyber threats while
                    preserving a good customer experience is central to that
                    success.
                  </p>
                  <p className="text-[18.7px] w-[94%] mt-[8px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose  italic">
                    “Knowing what is calling your API is a necessary element to
                    protect your mobile channel from scripts and bots.”
                  </p>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-right italic not-prose ">
                    - Alexandre Branquart, CIO/CTO & Co-Founder
                  </p>
                  <div className="md:block flex justify-center mt-[30px]">
                    <Link
                      to="/customer/deindeal/"
                      className="text-white bg-[#86b146] ml-[4px] p-[5px] pl-[23px] pr-[23px] font-bold text-[20px] w-max text-center md:text-left not-prose leading-[25px] rounded "
                    >
                      Read Deindeal's story
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-[100%] md:w-[41%] flex justify-center content-center flex-wrap pl-[0px] md:pl-[10px] mt-[25px] md:mt-[0px]">
            <StaticImage
              src={`../../components/site/customers/images/DEINdeal.png`}
              className="w-[315px] md:w-full md:max-w-[428px] h-auto"
              alt="Young woman scanning price tags with her smartphone in a clothing store"
            />
          </div>
        </div>
        {/* DEINdeal Content end */}
        <hr className="block md:hidden border-[3.3px] bg-[#b91684] border-[#b91684] w-[90%] m-auto mt-[40px] ml-[5%]" />

        {/* Idyllic Content start */}
        {/* <div className="block md:flex mt-[50px] mb-[20px]">
          <div className="md:w-[22%] mt-[30px] md:mt-[0px] flex flex-wrap justify-center md:justify-end content-center pl-[15px] not-prose pr-[25px] pb-[20px]">
            <StaticImage
              src={`../../components/site/customers/images/idyllic_logo.png`}
              className="w-full max-w-[200px] h-auto"
              alt="Papara_logo"
            />
          </div>

          <div className="md:w-[40%] flex justify-center pl-[6px] pt-[15px]">
            <div className="not-prose">
              <p className="text-[24px] md:text-[42px] font-black not-prose text-center md:text-left">
                Idyllic
              </p>
              <div className="flex justify-center md:block">
                <div>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose ">
                    The Idyllic team built a gaming app called 747, a real
                    money, skill-based betting app where the player solving a
                    math puzzle fastest wins. Puzzle questions could be captured
                    and solved programmatically. The very real prospect of
                    genuine players losing money to cheaters is a reputational
                    nightmare and risked the game being dead on arrival.
                  </p>
                  <p className="text-[18.7px] w-[94%] mt-[8px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose  italic">
                    “Once we had deployed Approov, game play increased by more
                    than 3x. It was amazing to see players engaging so
                    enthusiastically once they knew that automated cheaters had
                    been outlawed.”
                  </p>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-right italic not-prose ">
                    - EJinesh Parekh, Partner
                  </p>
                </div>
              </div>
              <div className="md:block flex justify-center mt-[30px]">
                <Link
                  to="/customer/idyllic/"
                  className="text-white bg-[#86b146] ml-[4px] p-[5px] pl-[23px] pr-[23px] font-bold text-[20px] w-max text-center md:text-left not-prose leading-[25px] rounded "
                >
                  Read Idyllic's story
                </Link>
              </div>
            </div>
          </div>

          <div className="w-[100%] md:w-[40%] flex justify-center mt-[25px] md:mt-[0px] pr-[10px]">
            <StaticImage
              src={`../../components/site/customers/images/Idyllic.png`}
              className="w-full max-w-[315px] md:max-w-[428px] h-auto"
              alt="Young man celebrating success with a fist pump while using a mobile phone outdoors"
            />
          </div>
        </div> */}
        {/* Idyllic Content end */}
        {/* <hr className="block md:hidden border-[3.3px] bg-[#b91684] border-[#b91684] w-[90%] m-auto mt-[40px] ml-[5%]" /> */}

        <div className="block md:flex mt-[40px]">

          <div className="md:w-[20%] mt-[30px] md:mt-[0px] flex flex-row md:flex-col justify-center content-center pl-[0px] md:pl-[15px] not-prose">
            <StaticImage
              src={`../../components/site/customers/images/Genopets_Horizontal_Logo.svg`}
              className="w-full max-w-[200px] h-auto"
              alt="Genopets company logo"
            />
          </div>

          <div className="md:w-[40%] flex justify-center pl-[0px] md:pl-[6px] pt-[15px]">
            <div className="not-prose">
              <p className="text-[24px] md:text-[42px] font-black not-prose text-center md:text-left">
                Genopets
              </p>
              <div className="flex justify-center md:block">
                <div>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose ">
                    Genopets, a global Move-to-Earn gaming app that rewards players for an active lifestyle, saw rapid growth upon launch. Unfortunately this attracted the attention of malicious users who were looking to abuse the backend API resources for their own benefit.
                  </p>
                  <p className="text-[18.7px] w-[94%] mt-[8px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose  italic">
                    “We saw an immediate 99.9% reduction of malicious access to our backend resources once Approov was implemented. Approov’s ability to provide unlimited and unthrottled protection is unlike native or any other mobile apps solutions.”
                  </p>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-right italic not-prose ">
                    - Kevin Kim, COO at Genopets
                  </p>
                  <div className="md:block flex justify-center mt-[30px]">
                    <Link
                      to="/customer/genopets/"
                      className="text-white bg-[#86b146] ml-[4px] p-[5px] pl-[23px] pr-[23px] font-bold text-[20px] w-max text-center md:text-left not-prose leading-[25px] rounded "
                    >
                      Read Genopets's story
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-[100%] md:w-[41%] flex justify-center content-center flex-wrap pl-[0px] md:pl-[10px] mt-[25px] md:mt-[0px]">
            <StaticImage
              src={`../../components/site/customers/images/Genopets.png`}
              className="w-[315px] md:w-full md:max-w-[428px] h-auto"
              alt="Background image of man standing in front of Sixt rental car; close up of a smartphone showing Sixt app in foreground."
            />
          </div>
        </div>
        <hr className="block md:hidden border-[3.3px] bg-[#b91684] border-[#b91684] w-[90%] m-auto mt-[40px] ml-[5%]" />

        {/* Metal_Pay Content start */}
        <div className="block md:flex md:flex-row-reverse mt-[40px]">

          <div className="md:w-[20%] mt-[30px] md:mt-[0px] flex flex-row md:flex-col justify-center content-center pl-[0px] md:pl-[15px] not-prose">
            <StaticImage
              src={`../../components/site/customers/images/MetalPay_logo.png`}
              className="w-full max-w-[200px] h-auto"
              alt="MetalPay company logo"
            />
          </div>

          <div className="md:w-[40%] flex justify-center pl-[0px] md:pl-[6px] pt-[15px]">
            <div className="not-prose">
              <p className="text-[24px] md:text-[42px] font-black not-prose text-center md:text-left">
                Metal Pay
              </p>
              <div className="flex justify-center md:block">
                <div>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose ">
                    Metal Pay provides users with an easy way to bank, trade in
                    cryptocurrency and make P2P payments. As the platform grew,
                    they experienced an increase in fraudulent activity from
                    bots and scripts attempting to communicate with the Metal
                    Pay API.
                  </p>
                  <p className="text-[18.7px] w-[94%] mt-[8px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose  italic"></p>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-right italic not-prose "></p>
                  <div className="md:block flex justify-center mt-[30px]">
                    <Link
                      to="/customer/metalpay/"
                      className="text-white bg-[#86b146] ml-[4px] p-[5px] pl-[23px] pr-[23px] font-bold text-[20px] w-max text-center md:text-left not-prose leading-[25px] rounded "
                    >
                      Read Metal Pay's story
                    </Link>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="w-[100%] md:w-[41%] flex justify-center content-center flex-wrap pl-[0px] md:pl-[10px] mt-[25px] md:mt-[0px]">
            <StaticImage
              src={`../../components/site/customers/images/Metal_Pay.png`}
              className="w-[315px] md:w-full md:max-w-[428px] h-auto"
              alt="Two smartphones side by side; both showing the Metal Pay app"
            />
          </div>
        </div>
        {/* Metal_Pay Content end */}
        <hr className="block md:hidden border-[3.3px] bg-[#b91684] border-[#b91684] w-[90%] m-auto mt-[40px] ml-[5%]" />

        {/* MV Content start */}
        <div className="block md:flex mt-[50px] mb-[20px]">
          <div className="md:w-[22%] mt-[30px] md:mt-[0px] flex flex-wrap justify-center md:justify-end content-center pl-[0px] md:pl-[15px] not-prose pr-[25px] pb-[20px]">
            <StaticImage
              src={`../../components/site/customers/images/MV_logo.png`}
              className="w-full max-w-[200px] h-auto"
              alt="MV company logo"
            />
          </div>

          <div className="md:w-[40%] flex justify-center pl-[0px] md:pl-[6px] pt-[15px]">
            <div className="not-prose">
              <p className="text-[24px] md:text-[42px] font-black not-prose text-center md:text-left">
                MV
              </p>
              <div className="flex justify-center md:block">
                <div>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose ">
                    MV is a Brazil-based provider of core <Link href="/market/mhealth/">healthcare</Link> information
                    and management systems, and has been delivering solutions
                    for hospitals, clinics, health plan operators, radiology
                    centers and public and private health networks for over 30
                    years.
                  </p>
                  <p className="text-[18.7px] w-[94%] mt-[8px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose  italic">
                    “Approov plugged an immediate API security hole which
                    pentesting had exposed in our platform, and we calculate
                    that the adoption of Approov will bring us a 10x RoI.”
                  </p>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-right italic not-prose ">
                    - Tiago Calado, Software Development Manager, MV
                  </p>
                </div>
              </div>
              <div className="md:block flex justify-center mt-[30px]">
                <Link
                  to="/customer/mv/"
                  className="text-white bg-[#86b146] ml-[4px] p-[5px] pl-[23px] pr-[23px] font-bold text-[20px] w-max text-center md:text-left not-prose leading-[25px] rounded "
                >
                  Read MV's story
                </Link>
              </div>
            </div>
          </div>

          <div className="w-[100%] md:w-[40%] flex justify-center mt-[25px] md:mt-[0px] pr-[0px] md:pr-[10px]">
            <StaticImage
              src={`../../components/site/customers/images/MV.png`}
              className="w-full max-w-[315px] md:max-w-[428px] h-auto"
              alt="Medical professional looking at a tablet"
            />
          </div>
        </div>
        {/* MV Content end */}
        <hr className="block md:hidden border-[3.3px] bg-[#b91684] border-[#b91684] w-[90%] m-auto mt-[40px] ml-[5%]" />

        {/* Scoffable Content start */}
        <div className="block md:flex md:flex-row-reverse mt-[50px] mb-[20px]">
          <div className="md:w-[22%] mt-[30px] md:mt-[0px] flex flex-wrap justify-center md:justify-end content-center pl-[0px] md:pl-[15px] not-prose pr-[0px] md:pr-[25px] pb-[20px]">
            <StaticImage
              src={`../../components/site/customers/images/scoffable_logo.png`}
              className="w-full max-w-[200px] h-auto"
              alt="Scoffable company logo"
            />
          </div>

          <div className="md:w-[40%] flex justify-center pl-[0px] md:pl-[6px] pt-[15px]">
            <div className="not-prose">
              <p className="text-[24px] md:text-[42px] font-black not-prose text-center md:text-left">
                Scoffable
              </p>
              <div className="flex justify-center md:block">
                <div>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose ">
                    Scoffable provides a fast and convenient online ordering
                    experience for takeaway consumers. With the increase in
                    online transactions and the use of mobile apps, protecting
                    sensitive data has not only become a part of doing business,
                    but a requirement to earn the trust of customers.
                  </p>
                  <p className="text-[18.7px] w-[94%] mt-[8px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose  italic">
                    “We couldn’t find anything else quite like Approov, for us
                    it solved a number of problems.”
                  </p>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-right italic not-prose ">
                    - Daniel Jones, Founding Partner
                  </p>
                </div>
              </div>
              <div className="md:block flex justify-center mt-[30px]">
                <Link
                  to="/customer/scoffable/"
                  className="text-white bg-[#86b146] ml-[4px] p-[5px] pl-[23px] pr-[23px] font-bold text-[20px] w-max text-center md:text-left not-prose leading-[25px] rounded "
                >
                  Read Scoffable's story
                </Link>
              </div>
            </div>
          </div>

          <div className="w-[100%] md:w-[40%] flex justify-center mt-[25px] md:mt-[0px] pr-[0px] md:pr-[10px]">
            <StaticImage
              src={`../../components/site/customers/images/Scoffable.png`}
              className="w-full max-w-[315px] md:max-w-[428px] h-auto"
              alt="Rear view of a food delivery rider on a bicycle"
            />
          </div>
        </div>
        {/* Scoffable Content end */}
        <hr className="block md:hidden border-[3.3px] bg-[#b91684] border-[#b91684] w-[90%] m-auto mt-[40px] ml-[5%]" />

        {/* Sixt Content start */}
        <div className="block md:flex mt-[40px]">


          <div className="md:w-[20%] mt-[30px] md:mt-[0px] flex flex-row md:flex-col justify-center content-center pl-[0px] md:pl-[15px] not-prose">
            <StaticImage
              src={`../../components/site/customers/images/Sixt_logo.png`}
              className="w-full max-w-[200px] h-auto"
              alt="Sixt company logo"
            />
          </div>

          <div className="md:w-[40%] flex justify-center pl-[0px] md:pl-[6px] pt-[15px]">
            <div className="not-prose">
              <p className="text-[24px] md:text-[42px] font-black not-prose text-center md:text-left">
                Sixt
              </p>
              <div className="flex justify-center md:block">
                <div>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose ">
                    Keeping pace with the consumer demand, car sharing has
                    become an increasingly popular alternative to owning — and
                    customers want to use mobile phones to access such services.
                  </p>
                  <p className="text-[18.7px] w-[94%] mt-[8px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose  italic">
                    “We looked around for a solution which could authenticate
                    when API requests were coming from our mobile apps, and
                    that’s when we came across Approov.”
                  </p>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-right italic not-prose ">
                    - Nico Gabriel, President SixtX
                  </p>
                  <div className="md:block flex justify-center mt-[30px]">
                    <Link
                      to="/customer/sixt/"
                      className="text-white bg-[#86b146] ml-[4px] p-[5px] pl-[23px] pr-[23px] font-bold text-[20px] w-max text-center md:text-left not-prose leading-[25px] rounded "
                    >
                      Read SIXT's story
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-[100%] md:w-[41%] flex justify-center content-center flex-wrap pl-[0px] md:pl-[10px] mt-[25px] md:mt-[0px]">
            <StaticImage
              src={`../../components/site/customers/images/Sixt.png`}
              className="w-[315px] md:w-full md:max-w-[428px] h-auto"
              alt="Background image of man standing in front of Sixt rental car; close up of a smartphone showing Sixt app in foreground."
            />
          </div>
        </div>
        {/* Sixt Content end */}
        <hr className="block md:hidden border-[3.3px] bg-[#b91684] border-[#b91684] w-[90%] m-auto mt-[40px] ml-[5%]" />

        {/* temi Content start */}
        <div className="block md:flex md:flex-row-reverse mt-[50px]">
          <div className="md:w-[22%] mt-[30px] md:mt-[0px] flex flex-wrap justify-center md:justify-end content-center pl-[0px] md:pl-[15px] not-prose pr-[0px] md:pr-[25px] pb-[20px]">
            <StaticImage
              src={`../../components/site/customers/images/temi_logo.png`}
              className="w-full max-w-[200px] h-auto"
              alt="Temi company logo"
            />
          </div>

          <div className="md:w-[40%] flex justify-center pl-[0px] md:pl-[6px] pt-[15px]">
            <div className="not-prose">
              <p className="text-[24px] md:text-[42px] font-black not-prose text-center md:text-left">
                Temi
              </p>
              <div className="flex justify-center md:block">
                <div>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose ">
                    Robotemi is a company that develops and sells Temi - an open
                    platform robot that can be utilized in many business areas.
                    The company’s current focus sectors are senior care,
                    hospitals, real estate and retail stores.
                  </p>
                  <p className="text-[18.7px] w-[94%] mt-[8px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-center md:text-left not-prose  italic">
                    “We tried to secure our API endpoints with in-house
                    protections, so we know how difficult it is. Approov is the
                    industrial version of what we needed to ensure that only our
                    client software could use our APIs.”
                  </p>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-right italic not-prose ">
                    - Ben Levy, VP R&D, Robotemi
                  </p>
                </div>
              </div>
              <div className="md:block flex justify-center mt-[30px]">
                <Link
                  to="/customer/robotemi/"
                  className="text-white bg-[#86b146] p-[5px] pl-[23px] pr-[23px] font-bold text-[20px] w-max text-center md:text-left not-prose rounded "
                >
                  READ ROBOTEMI'S STORY
                </Link>
              </div>
            </div>
          </div>

          <div className="w-[100%] md:w-[40%] flex justify-center mt-[25px] md:mt-[0px] pr-[0px] md:pr-[10px]">
            <StaticImage
              src={`../../components/site/customers/images/temi.png`}
              className="w-full max-w-[315px] md:max-w-[428px] h-auto"
              alt="Close up of a Temi personal robot"
            />
          </div>
        </div>
        {/* temi Content end */}
        <hr className="block md:hidden border-[3.3px] bg-[#b91684] border-[#b91684] w-[90%] m-auto mt-[40px] ml-[5%]" />
      </div>
    </PageLayout>
  )
}

export default ProductOverviewPage
